import { Atom } from ":mods";
import { useLocation } from "@solidjs/router"; 
import { useNavigate } from "@solidjs/router";

export function ThankYouPage(){
      const location = useLocation();
      const navigate = useNavigate();
      console.log(location.pathname); 
      
      setTimeout(()=>{
            if (location.pathname.includes("tutor")){       
                  navigate("/tutor", { replace: true });
            }else{
                  navigate("/student", { replace: true });
            }
      },5000)

      return(
            <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
                   <Atom.Progress.ProgressBar currnetStep={4} stepsCount={4} hideSteps stepName="Completed!" />
                  <h1 class="mb-20px text-28px font-medium">Thank you</h1>
                  <p class="text-16px w-390px text-center">Quisque at semper ipsum. Etiam ultrices augue ut tellus dignissim, hendrerit faucibus purus</p>
                  </section>
      )
}